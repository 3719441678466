import axiosInstance from '../../../services/axios';
import { commonActions } from '../CommonSlice';
import { suiteActions } from './SuiteSlice';

export const fetchAllSuites = () => {
  return async (dispatch) => {
    const fetchData = async () => {
      const response = await axiosInstance({
        method: 'get',
        url: '/v1/test_suites/all?page=0&countPerPage=5000'
      });
      const data = response.data;
      return data;
    };
    try {
      dispatch(commonActions.showLoadingIcon(true));
      const allSuites = await fetchData();
      dispatch(suiteActions.updateAllSuiteList(allSuites));
      dispatch(commonActions.showLoadingIcon(false));
    } catch (error) {
      dispatch(commonActions.showLoadingIcon(false));
    }
  };
};

export const fetchAllSuitesForUser = () => {
  return async (dispatch) => {
    const fetchData = async () => {
      const response = await axiosInstance({
        method: 'get',
        url: '/v1/test_suites'
      });
      const data = response.data;
      return data;
    };
    try {
      dispatch(commonActions.showLoadingIcon(true));
      const suites = await fetchData();
      dispatch(suiteActions.updateSuiteList(suites));
      dispatch(commonActions.showLoadingIcon(false));
    } catch (error) {
      dispatch(commonActions.showLoadingIcon(false));
    }
  };
};

export const fetchSuiteById = (id) => {
  return async (dispatch) => {
    const fetchData = async () => {
      const response = await axiosInstance({
        method: 'get',
        url: `/v1/test_suites/${id}`
      });
      const data = response.data;
      return data;
    };
    try {
      dispatch(commonActions.showLoadingIcon(true));
      const suite = await fetchData();
      dispatch(suiteActions.updateSuite(suite));
      dispatch(commonActions.showLoadingIcon(false));
    } catch (error) {
      dispatch(commonActions.showLoadingIcon(false));
    }
  };
};

export const createSuite = (suiteData) => {
  return (dispatch) => {
    const addData = async () => {
      await axiosInstance({
        method: 'post',
        url: '/v1/test_suites',
        data: suiteData
      });
    };
    try {
      return addData();
    } catch (error) {
      dispatch(commonActions.showLoadingIcon(false));
    }
  };
};

export const updateSuite = (id, suiteData) => {
  return (dispatch) => {
    const updateData = async () => {
      await axiosInstance({
        method: 'put',
        url: `/v1/test_suites/${id}`,
        data: suiteData
      });
    };
    try {
      return updateData();
    } catch (error) {
      dispatch(commonActions.showLoadingIcon(false));
    }
  };
};

export const deleteSuite = (id) => {
  return (dispatch) => {
    const deleteData = async () => {
      await axiosInstance({
        method: 'delete',
        url: `/v1/test_suites/${id}`
      });
    };
    try {
      return deleteData();
    } catch (error) {
      dispatch(commonActions.showLoadingIcon(false));
    }
  };
};
